<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col span="3">
        <div
          class="p-l-10 p-r-10 workplatform-radio-button"
          v-if="isAuth('settlement_transaction_view')"
          @click="handleChangeTag(1)"
        >
          交易
        </div>
        <div
          class="p-l-10 p-r-10 workplatform-radio-button"
          v-if="isAuth('settlement_maintenance_view')"
          @click="handleChangeTag(2)"
        >
          运维
        </div>
        <div class="p-l-10 p-r-10 workplatform-radio-button-active">收款</div>
      </i-col>
      <i-col span="2">
        <Select size="small" v-model="invoiced" placeholder="开票状态">
          <Option
            v-for="item in invoicedArray"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3">
        <DatePicker
          v-model="startDate"
          type="date"
          size="small"
          placeholder="付款起始日期"
        ></DatePicker>
      </i-col>
      <i-col span="3">
        <DatePicker
          v-model="endDate"
          type="date"
          size="small"
          placeholder="付款结束日期"
        ></DatePicker>
      </i-col>
      <i-col span="3">
        <Select
          v-model="currentCompanyId"
          clearable
          size="small"
          placeholder="公司列表"
        >
          <Option
            v-for="(company, index) in companyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3">
        <i-input
          v-model="keyword"
          size="small"
          clearable
          placeholder="如：备注"
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          type="primary"
          size="small"
          icon="ios-search"
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
        <Button
          type="success"
          size="small"
          class="m-l-5"
          v-if="isAuth('performance_create')"
          @click="createNewPerformance"
          >新建收款</Button
        >
        <!-- <Button
          type="success"
          size="small"
          class="m-l-5"
          @click="showDownLoadProgrammeModal = true"
          >下载明细</Button
        > -->
      </i-col>
    </Row>

    <createTransactionPayment
      ref="createTransactionPayment"
    ></createTransactionPayment>
    <performanceData ref="performanceData" @editPerformance="editPerformance"></performanceData>
    <Modal v-model="showDownLoadProgrammeModal">
      <h3 class="p-b-10" slot="header">请选择下载类型</h3>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
              <span>全部下载</span>
            </Radio>
            <Radio label="2">
              <span>勾选下载</span>
            </Radio>
          </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" @click="showDownLoadProgrammeModal = false"
          >取消</Button
        >
        <Button type="primary" @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import createTransactionPayment from './createTransactionPayment'
import performanceData from './performanceData'

import { downloadFileRequest } from '@/utils/download'
import { ParseDate, ParseDateForMonth } from '@/utils/dateFormat'
import { getcompanylistbytype } from '@/api/os/company'

export default {
  components: {
    createTransactionPayment,
    performanceData
  },
  data () {
    return {
      loadSearch: false,
      invoicedArray: [
        { id: -1, name: '全部状态' },
        { id: 0, name: '未开票' },
        { id: 1, name: '已开票' }
      ],
      invoiced: -1,
      startDate: '',
      endDate: '',
      companyArray: [],
      currentCompanyId: null,
      keyword: '',
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1'
    }
  },
  created () {
    this.initCompanyArray()
  },
  methods: {
    // 获取结算方公司列表
    initCompanyArray () {
      getcompanylistbytype({ companyId: this.companyId, type: 1 }).then(
        (res) => {
          this.companyArray = res
          this.currentCompanyId = null
          this.search()
        }
      )
    },
    search () {
      const query = {
        agentCompanyId: this.currentCompanyId,
        invoiced: this.invoiced,
        startDate: this.startDate ? ParseDate(this.startDate) : null,
        endDate: this.endDate ? ParseDate(this.endDate) : null,
        keyword: this.keyword
      }

      this.$store.commit('set_performanceQuery', query)
      this.$store.commit('set_settlement_update', new Date())
    },
    handleChangeTag (tag) {
      let moduleType = ''

      switch (tag) {
        case 1:
          moduleType = 'transaction' // 交易
          break
        case 2:
          moduleType = 'maintenance' // 运维
          break
        default:
          break
      }
      this.$store.commit('set_settlement_moduleType', moduleType)
    },
    createNewPerformance () {
      this.$refs.createTransactionPayment.showModal(
        -1,
        this.companyArray,
        this.currentCompanyId
      )
    },
    editPerformance (e) {
      this.$refs.createTransactionPayment.showModal(
        e.id,
        this.companyArray,
        this.currentCompanyId
      )
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {}
      if (parseInt(that.downLoadProgramme) === 1) {
        params.agentCompanyId = that.transactionSettlementQuery.agentCompanyId
        params.startDate = that.transactionSettlementQuery.startDate
        params.endDate = that.transactionSettlementQuery.endDate
        params.keyword = that.transactionSettlementQuery.keyword
        params.invoiced = that.transactionSettlementQuery.invoiced
        params.billType = 1
        params.publisherId = that.$store.getters.token.userInfo.publisherId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.billIdArrJsonStr = JSON.stringify(that.feeitemIds)
          params.billType = 1
        }
      }

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr =
            ParseDateForMonth(params.startDate) +
            '至' +
            ParseDateForMonth(params.endDate)
        }
      }
      const filename = '交易结算单(' + dateStr + ').xlsx'
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v2/bill/exportbills',
        params,
        filename
      )
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    transactionSettlementQuery () {
      return this.$store.state.settlement.transactionSettlementQuery
    },
    feeitemIds () {
      return this.$store.state.settlement.feeitemIds || []
    }
  }
}
</script>
