<template>
  <div>
    <Table stripe :data="list" :columns="tableColumns"  @on-selection-change="selectionChange"></Table>
    <Row>
      <i-col span='12'>
        <!-- <span style="height: 28px;line-height: 28px;">已选中{{feeitemIds.length}}条</span> -->
      </i-col>
      <i-col  span='12'>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="handlePageChanged"
          ></Page>
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getbillrepaymentpage, deletebillrepayment } from '@/api/statement/billrepayment'

export default {
  data () {
    return {
      total: 0,
      query: {
        billType: 1,
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        invoiced: null,
        agentCompanyId: null,
        startDate: '',
        endDate: ''
      },
      list: [],
      tableColumns: [
        // {
        //   type: 'selection',
        //   key: 'id',
        //   width: 60,
        //   align: 'center'
        // },
        {
          title: '付款公司',
          key: 'agentCompanyName'
        },
        {
          title: '付款日期',
          key: 'date'
        },
        {
          title: '含税金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '不含税金额',
          key: 'amountWithoutTax',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amountWithoutTax)
              )
            ])
          }
        },
        {
          title: '税率',
          key: 'taxRatio',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.taxRatio + '%'
              )
            ])
          }
        },
        {
          title: '已开发票',
          width: 100,
          render: (h, data) => {
            if (data.row.invoiced) {
              return h('Icon', {
                props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
              })
            } else {
              return h('Icon', {
                props: { type: 'md-close', size: 16, color: '#ed4014' }
              })
            }
          }
        },

        {
          title: '创建时间',
          key: 'createTime'
          // render: (h, params) => {
          //   return h('div', [h('span', '2020-06-13 12:54:47')])
          // }
        },
        {
          title: '备注',
          key: 'mark'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            const that = this
            return that.isAuth('performance_create') ? h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$emit('editPerformance', { id: params.row.id })
                    }
                  }
                },
                '编辑'
              ),
              h(
                'a',
                {
                  style: { marginRight: '5px', color: '#ef4f4f' },
                  on: {
                    click: () => {
                      that.$Modal.confirm({
                        title: '操作确认',
                        content: '<p>是否要确认删除此收款项？</p>',
                        loading: true,
                        onOk: () => {
                          deletebillrepayment({
                            billrepaymentId: params.row.id
                          }).then((res) => {
                            if (res && !res.errcode) {
                              that.$Notice.success({ desc: '删除成功' })
                              if (that.feeitemIds.includes(params.row.id)) {
                                that.feeitemIds.splice(that.feeitemIds.indexOf(params.row.id), 1)
                                that.$store.commit('set_settlement_feeitemIds', that.feeitemIds)
                              }
                              // that.reloadPage()
                              this.$store.commit('set_settlement_update', new Date())
                            }
                            that.$Modal.remove()
                          }).catch(() => {
                            that.$Modal.remove()
                          })
                        }
                      })
                    }
                  }
                },
                '删除'
              )

            ]) : h('div', [])
          }
        }
      ],

      currentPageAllItemIds: []
    }
  },
  created () {
    this.$store.commit('set_settlement_feeitemIds', null)
  },
  computed: {
    performanceQuery () {
      return this.$store.state.settlement.performanceQuery
    },
    feeitemIds () {
      return this.$store.state.settlement.feeitemIds || []
    },
    beginUpdate () {
      return this.$store.state.settlement.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.query.agentCompanyId = this.performanceQuery.agentCompanyId
      this.query.minDate = this.performanceQuery.startDate
      this.query.maxDate = this.performanceQuery.endDate
      this.query.keyword = this.performanceQuery.keyword
      this.query.invoiced = this.performanceQuery.invoiced === -1 ? null : (this.performanceQuery.invoiced === 1)
      this.query.publisherId = this.$store.getters.token.userInfo.publisherId
      this.reloadPage()
    }

  },
  methods: {
    reloadPage () {
      this.query.pageNumber = 1
      this.loadPage()
    },
    loadPage () {
      getbillrepaymentpage(this.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = this.feeitemIds.includes(item.id)
            return item
          })
          this.currentPageAllItemIds = res.list.map(item => {
            return item.id
          })
        }

        this.list = res.list
        this.total = res.totalRow
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.reloadPage()
    },
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map(item => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach(item => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
          }
        }
      })
      this.$store.commit('set_settlement_feeitemIds', that.feeitemIds)
    }

  }
}
</script>
